import React from 'react'
import css from './footer.module.css'
import Logo from '../../assets/logo.png'
import {
    InboxIcon,
    PhoneIcon,
    LocationMarkerIcon,
    LoginIcon,
    UsersIcon,
    LinkIcon,
} from "@heroicons/react/outline"

const footer = () => {
  return (
    <div className={css.cFooterWrapper}>
        <hr />
        <div className={css.cFooter}>
            <div className={css.cLogo}>
                <img src={Logo} alt="" />
                <span>Amazon</span>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Contact Us</span>

                    <span className={css.pngLine}>
                        <LocationMarkerIcon className={css.icon}/>
                        <span>111 north avenue Orlando, Fl 32808</span>
                    </span>

                    <span className={css.pngLine}>
                        {" "}
                        <PhoneIcon className={css.icon}/>
                        <a href='tel: 352-206-208'>352-206-208</a>
                    </span>

                    <span className={css.pngLine}>
                        {" "}
                        <InboxIcon className={css.icon}/>
                        <a href='mailto: support@amazon.com'>support@amazon.com</a>
                    </span>
                </div>
            </div>

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Accounts</span>

                    <span className={css.pngLine}>
                        <LoginIcon className={css.icon}/>
                        <span>Sign In</span>
                    </span>
                </div>
            </div>

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Company</span>

                    <span className={css.pngLine}>
                        <UsersIcon className={css.icon}/>
                        <span>About Us</span>
                    </span>
                </div>
            </div>

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Resourses</span>

                    <span className={css.pngLine}>
                        <LinkIcon className={css.icon}/>
                        <span>Safety Privacy & Terms</span>
                    </span>
                </div>
            </div>            
        </div>
        <div className={css.copyright}>
            <span>Copyright ©2022 by Amazon,  lnc.</span>
            <span>All rights reserved</span>
        </div>
    </div>

  )
}

export default footer
