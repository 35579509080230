import React from "react";
import css from "./Hero.module.css";
import HeroImg from "../../assets/hero.png";
import { RiShoppingBagFill } from "react-icons/ri";
import { BsArrowRight } from "react-icons/bs";
import { motion } from "framer-motion";
import { useState, useEffect } from 'react';

const Hero = () => {
  const transition = { duration: 3, type: "spring" };
  const [isVisible, setIsVisible] = useState(false);

  // Function to handle the scroll event
  const handleScroll = () => {
    // You can adjust the threshold as needed
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(true);
    }
  };

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // When the component initially loads, set isVisible to true
    setIsVisible(true);
  }, []);

  
  return (
    <div className={css.container}>
      {/* {left side} */}
      <div className={css.h_sides}>
        <span className={css.text1}>skin protection cream</span>

        <div className={css.text2}>
          <span>Trending Collection</span>
          <span>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam
            ad vitae.{" "}
          </span>
        </div>
      </div>
      {/* {middle side hero img} */}
      <div className={css.wrapper}>
        <motion.div
          initial={{ bottom: "2rem" }}
          whileInView={{ bottom: "0rem" }}
          transition={transition}
          className={css.blueCircle}
        ></motion.div>

        <motion.img
          transition={transition}
          initial={{ bottom: "-2rem" }}
          whileInView={{ bottom: "0rem" }}
          src={HeroImg}
          alt=""
          width={600}
        />

        <div className={`${css.cart2} ${isVisible ? css.visible : ''}`}>
          <RiShoppingBagFill />
          <div className={css.signup}>
            <span>Best Sign Up Offers</span>
            <div>
              <BsArrowRight />
            </div>
          </div>
        </div>
      </div>

      {/* {right side} */}
      <div className={css.h_sides}>
        <div className={css.traffic}>
          <span>1.5m</span>
          <span>Monthly Traffic</span>
        </div>
        <div className={css.customers}>
          <span>100K</span>
          <span>Happy Customers</span>
        </div>
      </div>
    </div>
  );
};

export default Hero;
