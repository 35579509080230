import "./App.css";
import Hero from "./components/Hero/Hero";
import Header from "./components/header/Header";
import Slider from "./components/Slider/Slider";
import Virtual  from "./components/Virtual/virtual"
import Products from "./components/Products/Products"
import Testimonial from "./components/Testimonial/Testimonial";
import Footer from "./components/Footer/footer"


function App() {
  return (
    <div className="App">
     <Header/>
     <Hero/> 
     <Slider/>
     <Virtual/>
     <Products/>
     <Testimonial/>
     <Footer/>
    </div>
  );
}

export default App;
