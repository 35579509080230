import React, { useState } from "react";
import css from "./Products.module.css";
import Plane from "../../assets/plane.png";
import { ProductsData } from "../../data/products";
import { useAutoAnimate } from '@formkit/auto-animate/react';


const Products = () => {

  const [parent] = useAutoAnimate()

    const [MenuProducts, setMenuProducts]= useState(ProductsData)

    const [category, setCategory] = useState("All");

  // Function to filter products based on category
  const filterProducts = (selectedCategory) => {
    if (selectedCategory === "All") {
      setMenuProducts(ProductsData);
    } else {
      const filteredProducts = ProductsData.filter(
        (product) => product.type === selectedCategory
      );
      setMenuProducts(filteredProducts);
    }
    setCategory(selectedCategory);
  };

  return (
    <div className={css.container}>
      <img src={Plane} alt="" />
      <h1>Our Featured Products</h1>

      <div className={css.products}>
        <ul className={css.menu}>
          <li onClick={() => filterProducts("All")}>All</li>
          <li onClick={() => filterProducts("skin care")}>Skin Care</li>
          <li onClick={() => filterProducts("conditioner")}>Conditioners</li>
          <li onClick={() => filterProducts("foundation")}>Foundations</li>
        </ul>

        <div className={css.list} ref={parent}>
            {
                MenuProducts.map((product, i)=>(
                    <div className={css.product} key={i}>

                      <div className="left_s">
                        <div className="name">
                          <span>{product.name}</span>
                          <span>{product.detail}</span>
                        </div>
                        <span>{product.price}$</span>
                        <div>Shop Now</div>
                      </div>
                      <img src={product.img} alt="" className="img_p"/>
                      
                    </div>
                ))
                
            }
        </div>
      </div>
    </div>
  );
};

export default Products;
